import isBoolean from 'lodash/isBoolean'
import kebabCase from 'lodash/kebabCase'
import noop from 'lodash/noop'

import { Field, useFormikContext } from 'formik'
import React, { useCallback, useMemo } from 'react'

import styles from '@/styles/Input.module.css'

const RadioCheckbox = ({
    id,
    options,
    name,
    label,
    type = 'radio',
    noSpace = false,
    formClass,
    labelClass,
    radioGrid,
    labelField,
    customClass,
    disabled,
    betweenSpace,
    onChange = noop,
    isString = false,
}) => {
    const { getFieldMeta, setFieldValue } = useFormikContext() || {}
    const { value } = getFieldMeta ? getFieldMeta(name) : {}

    useMemo(() => id || `${kebabCase(label)}`, [id, label])

    const handleChange = useCallback(
        (event) => {
            let val = event.target.value === 'true'
            if (type === 'checkbox' && options?.length === 1) {
                val = event.target.checked
            }
            setFieldValue(name, val)
            onChange(val)
        },
        [type, options?.length, setFieldValue, name, onChange]
    )

    const handleChangeForString = useCallback(
        (event) => {
            let val = event.target.value
            if (type === 'checkbox' && options?.length === 1) {
                val = event.target.checked
            }
            setFieldValue(name, val)
            onChange(val)
        },
        [type, options?.length, setFieldValue, name, onChange]
    )

    const renderOptions = (options, level = 0) => {
        return options.map((option, key) => (
            <div
                key={`${id}_${key}`}
                className={`flex items-start relative ${
                    type === 'radio'
                        ? 'custom-radio-field'
                        : 'custom-checkbox-field'
                } ${betweenSpace} ${
                    option?.disabled ? 'disabled' : ''
                } ml-${level * 6}`} // margin left for nesting
            >
                <label
                    htmlFor={`${id}_${key}`}
                    className={`cursor-pointer ${labelField}`}
                >
                    {isBoolean(option?.value) ? (
                        <input
                            id={`${id}_${key}`}
                            type={type}
                            name={name}
                            checked={value === option.value}
                            onChange={handleChange}
                            value={option.value}
                            disabled={option?.disabled}
                            className={`${styles.radioInput} withBoolean`}
                        />
                    ) : (
                        <Field
                            id={`${id}_${key}`}
                            type={type}
                            name={name}
                            value={option.value}
                            disabled={option?.disabled}
                            className={`${styles.radioInput} withString`}
                            {...(isString
                                ? { onChange: handleChangeForString }
                                : null)}
                        />
                    )}
                    <span
                        className={`relative top-[-2px] ml-6 text-sm text-interface-900 ${styles.radioLabel} ${customClass}`}
                    >
                        {option.label}
                    </span>
                    <span className="top-0 checkmark"></span>
                </label>

                {/* Render nested options */}
                {option.nestedOptions && (
                    <div className={`mt-5`}>
                        {renderOptions(option.nestedOptions, level + 1)}
                    </div>
                )}
            </div>
        ))
    }

    return (
        <div
            className={`${styles.formGroup} ${
                noSpace ? styles.noSpace : ''
            } ${formClass}`}
        >
            {label && (
                <label
                    className={`block mb-2 text-sm font-acumin-bold text-interface-600 ${labelClass}`}
                >
                    {label}
                </label>
            )}
            <div
                className={`gap-3 ${styles.radioGroup} ${
                    radioGrid ? radioGrid : 'block '
                } ${disabled == true ? 'disabled' : ''}`}
            >
                {renderOptions(options)}
            </div>
        </div>
    )
}

export default RadioCheckbox
