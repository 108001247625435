import { useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo, forwardRef } from 'react'
import kebabCase from 'lodash/kebabCase'
import styles from '@/styles/Input.module.css'

const TextBox = forwardRef(
    (
        {
            id,
            name,
            placeholder,
            label,
            type = 'text',
            ariaLabel,
            variant,
            maxLength,
            noSpace = false,
            size,
            disabled = false,
            readOnly = false,
            autoFocus = false,
            customcss,
            cardinput,
            labelClass,
            iconClass,
            customClass,
            labelSize,
            customCssContainer,
            useOnlyError = false,
            onChange,
            value: valueProp,
        },
        ref
    ) => {
        const { handleBlur, handleChange, getFieldMeta } =
            useFormikContext() || {}
        const { value, error, touched } = getFieldMeta ? getFieldMeta(name) : {}
        const id$ = useMemo(() => id || `${kebabCase(label)}`, [id, label])

        const handleKeyDown = useCallback(
            (event) => {
                if (
                    type === 'number' &&
                    (event.key === 'ArrowUp' || event.key === 'ArrowDown')
                ) {
                    event.preventDefault()
                }
            },
            [type]
        )

        const handleScroll = useCallback(
            (event) => {
                if (
                    type === 'number' &&
                    document.activeElement === event.target
                ) {
                    event.preventDefault()
                }
            },
            [type]
        )

        useEffect(() => {
            const inputElement = document.getElementById(id$)

            if (inputElement && type === 'number') {
                inputElement.addEventListener('wheel', handleScroll, {
                    passive: false,
                })
            }

            return () => {
                if (inputElement && type === 'number') {
                    inputElement.removeEventListener('wheel', handleScroll)
                }
            }
        }, [id$, type, handleScroll])

        useEffect(() => {
            if (autoFocus && ref?.current) {
                ref.current.focus()
            }
        }, [autoFocus, ref])

        return (
            <div
                className={`${styles.inputModule} ${customCssContainer} ${cardinput ? styles.cardInterface : ''} w-full`}
            >
                <div
                    className={`${styles.formGroup} ${noSpace ? styles.noSpace : ''} ${customClass}`}
                >
                    {label && (
                        <label
                            htmlFor={id$}
                            className={`${styles.inputLabel} ${styles[labelSize]} ${labelClass} text-interface-600 mb-1`}
                        >
                            {label}
                        </label>
                    )}
                    <input
                        ref={ref} // Use the ref from parent
                        id={id$}
                        name={name}
                        type={type}
                        disabled={disabled}
                        readOnly={readOnly}
                        maxLength={maxLength}
                        autoFocus={autoFocus}
                        className={`${styles.formControl} ${styles[variant]} ${styles[size]} ${
                            useOnlyError
                                ? error
                                    ? styles.hasError
                                    : ''
                                : error && touched
                                  ? styles.hasError
                                  : ''
                        } ${customcss}`}
                        aria-label={ariaLabel ? ariaLabel : label}
                        placeholder={placeholder}
                        onBlur={handleBlur}
                        onChange={onChange || handleChange}
                        autoComplete="off"
                        onKeyDown={handleKeyDown}
                        value={valueProp || value}
                    />
                    {iconClass && (
                        <i className={`${iconClass} ${styles.icon}`}></i>
                    )}
                </div>
            </div>
        )
    }
)

export default TextBox
